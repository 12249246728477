import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EventDetail = () => {
  const { id } = useParams(); // Get the event ID from the URL
  console.log("event id: ", id);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`https://kovebox-server-90387d3b18a6.herokuapp.com/api/events/${id}`);
        console.log("Detailed Event Response: ", response.data);
        console.log("Title: ", response.data?.title);
console.log("Image: ", response.data?.image);
console.log("Link: ", response.data?.contextLink);
        
        setEvent(response.data);
      
      } catch (err) {
        console.error("Error fetching event details:", err.message);
        setError("Failed to load event details.");
      }
    };
    if(id) {
    fetchEventDetails();
    }
  }, [id]);




  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        {/* Event Image */}
        {event.image ? (
          <img
            src={event.image}
            alt={event.title}
            className="w-full h-64 object-cover"
            onError={(e) => (e.target.src = "https://via.placeholder.com/600x400?text=No+Image")}
          />
        ) : (
          <img
            src="https://via.placeholder.com/600x400?text=No+Image"
            alt="No image available"
            className="w-full h-64 object-cover"
          />
        )}

        {/* Event Content */}
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-4">{event.title}</h1>
          <p className="text-gray-600 mb-4">{event.snippet}</p>

          {/* Event Location */}
          <p className="text-gray-800 font-semibold mb-2">
            Location: <span className="text-gray-600">{event.location || "Not specified"}</span>
          </p>

          {/* Event Time */}
          <p className="text-gray-800 font-semibold mb-4">
            Time: <span className="text-gray-600">{event.time || "Not specified"}</span>
          </p>

          {/* View Original Event Link */}
          <a
            href={event.contextLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-4 py-2 text-sm text-white bg-blue-500 rounded-lg hover:bg-blue-600"
          >
            View Original Event
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
