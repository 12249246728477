import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch initial events
    fetchEvents(offset);
  }, []);

  const fetchEvents = async (newOffset) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://kovebox-server-90387d3b18a6.herokuapp.com/api/events?offset=${newOffset}&limit=10`
      );

      const fetchedEvents = response.data;

      if (fetchedEvents.length > 0) {
        setEvents((prev) => [...prev, ...fetchedEvents]);
        setOffset((prev) => prev + 10);
      } else {
        setHasMore(false); // No more events to fetch
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 &&
      !loading &&
      hasMore
    ) {
      fetchEvents(offset);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [offset, loading, hasMore]);

  // const handleViewDetails = (id) => {
  //   navigate(`/events/${id}`);
  // };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
        {events.map((event) => (
          <div
            key={event.id}
            className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col items-center text-center"
          >
            {event.image ? (
              <img
                src={event.image}
                alt={event.title}
                className="w-full h-40 object-cover"
                style={{ objectFit: "cover", width: "100%", height: "150px" }}
              />
            ) : (
              <p>No image available</p>
            )}
            <div className="p-4">
              <h2 className="text-lg font-semibold">{event.title}</h2>
              <h3 className="text-lg font-semibold">{event.location}</h3>
              <h3 className="text-lg font-semibold">{event.date} - {event.time}</h3>
              <p className="text-sm text-gray-600 mt-2">
                {event.snippet} - {event.contextLink}
              </p>
              {/* <button
                onClick={() => handleViewDetails(event.id)}
                className="inline-block px-4 py-2 text-sm text-white bg-blue-500 rounded-lg hover:bg-blue-600"
              >
                View Details
              </button> */}
            </div>
          </div>
        ))}
      </div>
      {loading && <p>Loading more events...</p>}
      {!hasMore && <p>No more events to load.</p>}
    </div>
  );
};

export default EventList;
